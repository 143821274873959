.foo {
    background-color: theme-color("info");
}

.nounderline {
    text-decoration: none !important
}

.open-sans-font {
  font-family: "Open Sans", serif;
}

.no-list-style li {
  list-style-type: none;
}

.pointer-hand {
  cursor:pointer;
}

.highlight-text {
  text-shadow: 1px 1px $primary;
}

.blur {
  backdrop-filter: blur(1.5px);
}

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1.2s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}