$primary: rgb(83, 102, 107);
$primary-trans: rgba(83, 102, 107, 0.5);
$secondary: rgb(82, 118, 123);
$warning: rgb(183, 140, 130);
$danger: rgb(180, 185, 164);
$success: rgb(161, 224, 161);
$info: cyan;
$facebook: #1DA1F2;
$light: white;
$dark: black;
$light-gray: rgb(240, 233, 227);
$light-gray-trans: rgba(240, 233, 227, .75);
$grullo: #B3A394;
$transparent: #10254200;

$theme-colors: (
    'primary': $primary,
    'primary-trans': $primary-trans,
    'transparent': $transparent,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'light-gray': $light-gray,
    'grullo': $grullo,
    'facebook' :$facebook,
    'light-gray-trans': $light-gray-trans
);

@function theme-color($key: "primary") {
    @return map-get($theme-colors, $key);
}

html *
{
   font-family: "Arial", serif;
}